
/*
 * @Description: 打开公式弹窗事件
 * @version: 
 * @Author: 王鹏
 * @Date: 2023-09-20 15:34:44
 * @LastEditors: 王鹏
 * @LastEditTime: 2023-09-20 18:26:33
 */
import { luckysheetupdateCell } from './updateCell';
import insertFormula from './insertFormula';
import { isEditMode } from '../global/validate';
import formula from '../global/formula';
import tooltip from '../global/tooltip';
import locale from '../locale/locale';
import Store from '../store';

export function openFormulaDialog() {
    //公式栏处理
    const _locale = locale();
    const locale_formula = _locale.formula;

    //公式栏 fx按钮
    insertFormula.init();

    //点击函数查找弹出框
    if (Store.luckysheet_select_save.length == 0) {
        if (isEditMode()) {
            alert(locale_formula.tipSelectCell);
        }
        else {
            tooltip.info(locale_formula.tipSelectCell, "");
        }

        return;
    }

    let last = Store.luckysheet_select_save[Store.luckysheet_select_save.length - 1];

    let row_index = last["row_focus"], col_index = last["column_focus"];

    luckysheetupdateCell(row_index, col_index, Store.flowdata);

    let cell = Store.flowdata[row_index][col_index];
    if (cell != null && cell.f != null) {
        //单元格有计算
        let functionStr = formula.getfunctionParam(cell.f);
        if (functionStr.fn != null) {
            //有函数公式
            insertFormula.formulaParmDialog(functionStr.fn, functionStr.param);
        }
        else {
            //无函数公式
            insertFormula.formulaListDialog();
        }
    }
    else {
        //单元格无计算
        $("#luckysheet-rich-text-editor").html('<span dir="auto" class="luckysheet-formula-text-color">=</span>');
        $("#luckysheet-functionbox-cell").html($("#luckysheet-rich-text-editor").html());
        insertFormula.formulaListDialog();
    }
}