export default [
    {
        "n": "SUM",
        "t": 0,
        "m": [
            1,
            255
        ],
        "p": [
            {
                "example": "A2:A100",
                "require": "m",
                "repeat": "n",
                "type": "rangeall"
            },
            {
                "example": "2",
                "require": "o",
                "repeat": "y",
                "type": "rangeall"
            }
        ]
    },
    {
        "n": "AVERAGE",
        "t": 1,
        "m": [
            1,
            255
        ],
        "p": [
            {
                "example": "A2:A100",
                "require": "m",
                "repeat": "n",
                "type": "rangeall"
            },
            {
                "example": "B2:B100",
                "require": "o",
                "repeat": "y",
                "type": "rangeall"
            }
        ]
    },
    {
        "n": "COUNT",
        "t": 1,
        "m": [
            1,
            255
        ],
        "p": [
            {
                "example": "A2:A100",
                "require": "m",
                "repeat": "n",
                "type": "rangeall"
            },
            {
                "example": "B2:B100",
                "require": "o",
                "repeat": "y",
                "type": "rangeall"
            }
        ]
    },
    {
        "n": "MAX",
        "t": 1,
        "m": [
            1,
            255
        ],
        "p": [
            {
                "example": "A2:A100",
                "require": "m",
                "repeat": "n",
                "type": "rangenumber"
            },
            {
                "example": "B2:B100",
                "require": "o",
                "repeat": "y",
                "type": "rangenumber"
            }
        ]
    },
    {
        "n": "MIN",
        "t": 1,
        "m": [
            1,
            255
        ],
        "p": [
            {
                "example": "A2:A100",
                "require": "m",
                "repeat": "n",
                "type": "rangenumber"
            },
            {
                "example": "B2:B100",
                "require": "o",
                "repeat": "y",
                "type": "rangenumber"
            }
        ]
    },
]
