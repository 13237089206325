/*
 * @Description: 
 * @version: 
 * @Author: 王鹏
 * @Date: 2023-09-20 17:32:53
 * @LastEditors: 王鹏
 * @LastEditTime: 2023-09-21 09:43:47
 */
import en from './en'
import zh from './zh'
import es from './es'
import zh_tw from './zh_tw'
import Store from '../store';

export const locales = {en,zh,es,zh_tw}

function locale(){
    // return locales[Store.lang]; 暂时只支持zh
    return locales['zh'];
}

export default locale;